import { useState } from 'react';
import { Link } from "react-router-dom";
import { useGetMyFutureReservationsQuery } from "./reservationsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";
import ReservationListFutureItem from './ReservationListFutureItem';

function ReservationListFuture() {

    const {
        data: reservations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetMyFutureReservationsQuery();

    const [date, setDate] = useState(currentDate);
    const [locationReserved, setLocationReserved] = useState('');
    
    const [search, setSearch] = useState('')

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }
    
    if (isSuccess) {
        content = (
            <main className='container py-3'>
                <section>
                    { isLoading ? <LoadingSpinner /> : isError && {error} }

                    { reservations?.length > 0 && (
                        <>
                            <h2>Ma réservation</h2>
                            <hr />
                            <table className="table striped bordered hover responsive">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Client</th>
                                        <th scope="col">Service</th>
                                        <th scope="col">Details</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                            
                                <tbody>
                                    {
                                        reservations.map((reservation, index) => (
                                            <ReservationListFutureItem reservation={reservation} key={index} />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </>
                    )}

                    {reservations?.length === 0 && (
                        <div className="position-relative overflow-hidden p-md-5 m-md-3 text-center">
                            <div className="mx-auto">
                                <h2>Vous n'avez pas de réservation future</h2>
                                <h5>You have not scheduled any future reservation</h5>
                            </div>
                        </div>
                    )}
                </section>
            </main>
        )
    }

    return content
}

export default ReservationListFuture;